
import { defineComponent, SetupContext } from 'vue'
import { Story } from '@/model/Story'

export default defineComponent({
  name: `StoryForm`,
  components: {},
  props: {
    story: {
      type: Object,
      required: true,
    },
  },
  emits: ['update'],
  setup(props: Readonly<{ story: Story }>, context: SetupContext) {
    const update = (key: keyof Story, value: any) => {
      context.emit('update', { key, value })
    }

    return { update }
  },
})
