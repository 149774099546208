export interface Story {
  id?: number
  projectId?: number | null
  date?: string
  points?: number
  name?: string
  description?: string
  hasReport?: boolean
  validated?: boolean
}

export const DEFAULT_STORY: Story = {}
