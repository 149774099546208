
import { defineComponent, computed, ref, onMounted } from 'vue'
import StoryFormCard from '@/components/core/story/StoryFormCard.vue'
import { Toaster } from '@/common/Toaster'
import { utils } from '@/common/Utils'
import { USER_ROLES } from '@/model/User'
import { __ } from '@/locales'
import router from '@/router'
import { useRoute } from 'vue-router'
import { storeStory, StoreActionsStory, StoreGettersStory } from '@/store/story'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { Story } from '@/model/Story'
import { saveAs } from 'file-saver'

export default defineComponent({
  name: `ViewProject`,
  components: { StoryFormCard },
  props: {},
  emits: [],
  setup() {
    const disabledFormButton = ref(false)
    const loadingStory = ref(false)
    const loadingReport = ref(false)
    const editing = ref(false)

    const route = useRoute()
    const story = computed(() => storeStory.getters[StoreGettersStory.STORY])
    const report = computed(() => storeStory.getters[StoreGettersStory.REPORT])
    const date = computed(() => utils.UTCToShort(story.value.date))

    const canEdit = computed(
      (): boolean =>
        storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )

    const canDelete = computed(
      (): boolean =>
        storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN &&
        !editing.value,
    )

    onMounted(() => {
      const id = +route.params.id

      if (!id) {
        return router.back()
      }
      if (story.value.id === id) return

      loadingStory.value = true
      loadingReport.value = true

      storeStory.dispatch(StoreActionsStory.GET, id).then((json) => {
        loadingStory.value = false
        if (!json.success) {
          return router.back()
        }
        console.log(utils.UTCToShort(story.value.date))
      })

      storeStory.dispatch(StoreActionsStory.GET_REPORT, id).then(() => {
        loadingReport.value = false
      })
    })

    const loadReport = async (event: CustomEvent) => {
      const res = await storeStory.dispatch(StoreActionsStory.SET_REPORT, {
        base64: event.detail.base64,
        file: event.detail.file,
        storyId: story.value.id,
      })
      Toaster.toast(res)
    }

    const deleteStory = async () => {
      if (confirm(__('Story.askDelete').toString())) {
        const res = await storeStory.dispatch(
          StoreActionsStory.DELETE,
          story.value.id,
        )
        Toaster.toast(res)
      }
    }

    const deleteReport = async () => {
      if (confirm(__('sureDeleteReport'))) {
        const res = await storeStory.dispatch(
          StoreActionsStory.DELETE_REPORT,
          story.value.id,
        )
        Toaster.toast(res)
      }
    }

    const downloadReport = async () => {
      saveAs(report.value, `${story.value.name}.pdf`)
    }

    const updateStory = async (story: Story) => {
      disabledFormButton.value = true
      const res = await storeStory.dispatch(StoreActionsStory.UPDATE, {
        story,
        id: story.id,
      })
      Toaster.toast(res)
      if (res.success) {
        editing.value = false
      }
      disabledFormButton.value = false
    }
    const informCustomers = () => {
      console.log('informCustomers not implemented')
    }

    return {
      story,
      report,
      date,
      editing,
      canEdit,
      canDelete,
      loadingStory,
      loadingReport,
      loadReport,
      deleteReport,
      deleteStory,
      updateStory,
      disabledFormButton,
      informCustomers,
      downloadReport,
    }
  },
})
