
import { defineComponent, SetupContext, Ref, ref, watch, onMounted } from 'vue'
import { Toaster } from '@/common/Toaster'
import { Story, DEFAULT_STORY } from '@/model/Story'
import FormCard from '../FormCard.vue'
import StoryForm from './StoryForm.vue'
import { DigInputController } from '@digithia/input/common'
import { __ } from '@/locales'

export default defineComponent({
  name: `StoryFormCard`,
  components: { FormCard, StoryForm },
  props: {
    story: {
      type: Object,
      required: false,
      default: DEFAULT_STORY,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{ title?: string; story?: Story }>,
    context: SetupContext,
  ) {
    const storyForm: Ref<Story> = ref({})

    onMounted(() => {
      storyForm.value = JSON.parse(JSON.stringify(props.story))
    })

    watch(
      () => props.story,
      () => {
        storyForm.value = JSON.parse(JSON.stringify(props.story))
      },
    )

    const update = ({ key, value }: { key: keyof Story; value: any }) => {
      storyForm.value[key] = value
    }

    const validate = () => {
      if (DigInputController.checkInputsValidity('.form-card')) {
        context.emit('validate', storyForm.value)
      } else {
        Toaster.toast({
          type: 'warning',
          message: __(`invalidForm`),
        })
      }
    }

    const cancel = () => {
      storyForm.value = JSON.parse(JSON.stringify(props.story))
      context.emit('cancel')
    }

    return {
      update,
      storyForm,
      validate,
      cancel,
    }
  },
})
