
import { defineComponent, SetupContext } from 'vue'

export default defineComponent({
  name: `FormCard`,
  components: {},
  props: {
    title: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{
      title?: string
      disabled?: boolean
      loading?: boolean
    }>,
    context: SetupContext,
  ) {
    const validate = () => {
      context.emit('validate')
    }
    const cancel = () => {
      context.emit('cancel')
    }
    return { validate, cancel }
  },
})
