import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-40f4eaa1")
const _hoisted_1 = { class: "story-form" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("dig-input-text", {
      value: _ctx.story.name,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update('name', $event.target.value))),
      id: "name",
      required: ""
    }, _toDisplayString(_ctx.$t('Story.name')), 41, _hoisted_2),
    _createElementVNode("dig-input-text", {
      value: _ctx.story.date,
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update('date', $event.target.value))),
      id: "date",
      type: "date",
      required: ""
    }, _toDisplayString(_ctx.$t('Story.date')), 41, _hoisted_3),
    _createElementVNode("dig-input-number", {
      value: _ctx.story.points,
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.update('points', $event.target.value))),
      id: "points",
      required: ""
    }, _toDisplayString(_ctx.$t('Story.points')), 41, _hoisted_4),
    _createElementVNode("dig-input-textarea", {
      value: _ctx.story.description,
      onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.update('description', $event.target.value))),
      id: "description"
    }, _toDisplayString(_ctx.$t('Story.description')), 41, _hoisted_5)
  ]))
}